<div class="ui basic login segment" [class.loading]="isLoading">
    <a href="https://{{ translateService.currentLang === 'fr_CA' ? 'fr.' : '' }}humi.ca" target="_blank" class="logo">
        <app-humi-logo labelOrientation="horizontal" />
    </a>

    <div class="container">
        <app-steps [activeStep]="currentStepIndex" [steps]="[planReview, company, administrator, payment, getStarted]">
            <ui-banner variant="danger" class="margin-bottom-1" *ngIf="errorMessage">{{ errorMessage }}</ui-banner>
            <app-step #planReview step="forms.setup-admin-welcome.steps.planReview">
                @if (currentStep === 'planReview') {
                    <app-form-setup-admin-welcome
                        (valid)="stepValidated($event.valid, $event.data, 'planReview')"
                        [formData]="stepData.planReview"
                    ></app-form-setup-admin-welcome>
                }
            </app-step>
            <app-step #company step="forms.setup-admin-welcome.steps.company">
                @if (currentStep === 'company') {
                    <app-form-setup-company
                        (valid)="stepValidated($event.valid, $event.data, 'company')"
                        [formData]="stepData.company"
                    ></app-form-setup-company>
                }
            </app-step>
            <app-step #administrator step="forms.setup-admin-welcome.steps.administrator">
                @if (currentStep === 'administrator') {
                    <app-form-setup-admin
                        (valid)="stepValidated($event.valid, $event.data, 'administrator')"
                        [formData]="stepData.administrator"
                    ></app-form-setup-admin>
                }
            </app-step>
            <app-step #payment step="forms.setup-admin-welcome.steps.payment">
                @if (currentStep === 'payment') {
                    <app-form-setup-payment
                        (valid)="stepValidated($event.valid, $event.data, 'payment')"
                        [formData]="stepData.payment"
                    ></app-form-setup-payment>
                }
            </app-step>

            <app-step #getStarted step="forms.setup-admin-welcome.steps.getStarted">
                @if (currentStep === 'getStarted') {
                    <app-form-setup-complete
                        (valid)="stepValidated($event.valid, $event.data, 'getStarted')"
                        [formData]="stepData.getStarted"
                    >
                    </app-form-setup-complete>
                    <ui-button
                        (click)="goToDashboard()"
                        type="primary"
                        style="display: block; width: 265px; margin: auto"
                        [class.disabled]="!currentStepValid"
                        >{{ 'forms.setup-complete.takeToHomeScreen' | translate }}</ui-button
                    >
                }
            </app-step>
        </app-steps>

        @if (currentStep !== 'getStarted') {
            <div class="step-buttons">
                @if (currentStep !== 'planReview') {
                    <ui-button type="ghost" (click)="onBackStep()"
                        ><mat-icon svgIcon="chevronLeft" /> {{ 'previous' | translate }}
                    </ui-button>
                }

                <ui-button class="margin-left-auto" type="primary" [disabled]="!currentStepValid" (click)="onNextStep()"
                    >{{ 'next' | translate }} <mat-icon svgIcon="chevronRight"
                /></ui-button>
            </div>
        }
    </div>
</div>
