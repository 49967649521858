<form class="ui form" #form="ngForm" autocomplete="off">
    <p>{{ 'forms.setup-admin-welcome.reviewCostBreakdown' | translate }}</p>

    <div class="ui divider"></div>

    <table *ngIf="invoiceLineItems?.length" class="ui table">
        <thead>
            <tr>
                <th>{{ 'forms.setup-admin-welcome.module' | translate }}</th>
                <th></th>
                <th class="right aligned">{{ 'forms.setup-admin-welcome.pricing' | translate }}</th>
            </tr>
        </thead>
        <tbody>
            <ng-container *ngFor="let item of invoiceLineItems; let index = index">
                <tr *ngIf="item['amount'] > 0">
                    <td>{{ item['description'] }}</td>
                    <td></td>
                    <td class="right aligned">{{ item['amount'] / 100 | i18nNum: '$' }}</td>
                </tr>
            </ng-container>
        </tbody>
        <tfoot>
            <tr *ngIf="dueNowSubtotal">
                <td></td>
                <td class="right aligned">{{ 'forms.setup-admin-welcome.dueNow' | translate }}</td>
                <td class="right aligned">{{ dueNowSubtotal | i18nNum: '$' }}*</td>
            </tr>
            <tr *ngIf="subscription?.status === 'trialing'">
                <td></td>
                <td class="right aligned">{{ 'forms.setup-admin-welcome.firstInvoiceDate' | translate }}</td>
                <td class="right aligned">{{ subscription?.trialEnd | appDate }}</td>
            </tr>
            <tr *ngIf="monthlySubtotal">
                <td></td>
                <td class="right aligned">{{ 'forms.setup-admin-welcome.monthlySubtotal' | translate }}</td>
                <td class="right aligned">{{ formatCurrency(monthlySubtotal) | i18nNum: '$' }}*</td>
            </tr>

            <tr *ngIf="percentDiscount">
                <td></td>
                <td class="right aligned">{{ 'forms.setup-admin-welcome.discount' | translate }}</td>
                <td class="right aligned">{{ percentDiscount | i18nNum: '%' }}</td>
            </tr>

            <tr *ngIf="dollarDiscount && !percentDiscount">
                <td></td>
                <td class="right aligned">{{ 'forms.setup-admin-welcome.monthlyDiscount' | translate }}</td>
                <td class="right aligned">{{ formatCurrency(dollarDiscount) | i18nNum: '$' }}</td>
            </tr>

            <tr *ngIf="monthlySubtotal">
                <td></td>
                @if (percentDiscount) {
                    <td class="right aligned">
                        <strong>{{ 'forms.setup-admin-welcome.monthlyTotalAfterDiscount' | translate }}</strong>
                    </td>
                    <td class="right aligned">
                        <strong>{{ discountedMonthlyAfterPercentSubtotal | i18nNum: '$' }}</strong>
                    </td>
                } @else if (dollarDiscount) {
                    <td class="right aligned">
                        <strong>{{ 'forms.setup-admin-welcome.monthlyTotalAfterDiscount' | translate }}</strong>
                    </td>
                    <td class="right aligned">
                        <strong>{{ monthlySubtotalAfterDollarDiscount | i18nNum: '$' }}</strong>
                    </td>
                } @else {
                    <td class="right aligned">
                        <strong>{{ 'forms.setup-admin-welcome.monthlyTotal' | translate }}</strong>
                    </td>
                    <td class="right aligned">
                        <strong>{{ formatCurrency(monthlySubtotal) | i18nNum: '$' }}</strong>
                    </td>
                }
            </tr>

            <tr>
                <td></td>
                <td class="right aligned"></td>
                <td class="right aligned">
                    <small>{{ 'forms.setup-admin-welcome.plusApplicableTaxes' | translate }}</small>
                </td>
            </tr>
        </tfoot>
    </table>

    <div class="ui divider"></div>

    <div class="ui segment" *ngIf="!invoiceLineItems?.length && !isLoading">
        <mat-icon svgIcon="info"></mat-icon>&nbsp;&nbsp;&nbsp;
        {{ 'forms.setup-admin-welcome.cannotdisplayCustomPricing' | translate }}
    </div>

    <div class="ui segment" [class.loading]="isLoading" [class.disabled]="isLoading">
        <ng-container *ngIf="!formValid">
            <div id="card-element"></div>
            <div id="card-errors"></div>
        </ng-container>
        <ng-container *ngIf="formValid">
            <mat-icon svgIcon="check"></mat-icon>
            <strong>{{ 'forms.setup-admin-welcome.paymentMethodSaved' | translate }}</strong>
        </ng-container>
    </div>

    <ui-button type="primary" style="min-width: 225px" (click)="createToken()" [disabled]="formValid || isLoading">{{
        'forms.setup-admin-welcome.savePaymentMethod' | translate
    }}</ui-button>

    <div class="ui hidden divider"></div>
</form>
