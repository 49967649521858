<form class="ui form" #form="ngForm" autocomplete="off">
    <h2 class="ui header centered">
        <ng-container *ngIf="company">
            {{ 'forms.setup-welcome.header' | translate: { company_name: company?.name } }}
        </ng-container>
        <div class="sub header">{{ 'forms.setup-welcome.subheader' | translate }}</div>
    </h2>

    <div *ngIf="welcomeMessage" class="ui segment margin-bottom-2" [innerHtml]="welcomeMessage | sanitizeHtml"></div>
    <video-player *ngIf="video" [video]="video" [responsive]="true" class="margin-bottom-2"></video-player>

    <div class="flex justify-center margin-bottom-2">
        <mat-checkbox [(ngModel)]="formData.terms" #terms="ngModel" name="terms" required type="checkbox">
            <span [innerHTML]="'forms.setup-welcome.acknowledgeTermsPrivacy' | translate: URLS"></span>
        </mat-checkbox>
    </div>
</form>
