import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { FeatureFlag } from '@app/enums';
import { AuthService } from '@app/services/auth.service';
import { FeatureService } from '@app/services/feature.service';
import { Company } from '@company/../../models/company/company.model';
import { OnboardingOption } from '@employees/../../models/employee/onboarding-option.model';
import { Employee } from '@models/employee/employee.model';
import Video from '@models/videos/video.model';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-form-setup-welcome',
    templateUrl: './setup-welcome.form.html',
    styleUrls: ['./setup-welcome.responsive.scss'],
})
export class SetupWelcomeForm implements OnInit, OnDestroy {
    @ViewChild('form', { static: true }) form: NgForm;
    @Input() formData: any = {};
    @Input() employee: Employee | null;
    @Output() valid: EventEmitter<any> = new EventEmitter<any>();
    welcomeMessage: string;
    company: Company;
    videoWidth: number | null;
    video: Video | null;

    URLS = {
        en_CA: {
            termsOfServiceUrl: 'https://www.humi.ca/terms',
            privacyPolicyUrl: 'https://www.humi.ca/privacy',
        },
        fr_CA: {
            termsOfServiceUrl: 'https://fr.humi.ca/conditions',
            privacyPolicyUrl: 'https://fr.humi.ca/conditions/vie-privee',
        },
    }[this.translateService.currentLang];

    constructor(
        public auth: AuthService,
        private featureService: FeatureService,
        private translateService: TranslateService
    ) {}

    async ngOnInit(): Promise<void> {
        this.form.statusChanges.subscribe(() => {
            this.valid.emit({ valid: this.form.valid, data: this.formData });
        });

        this.company = await Company.find(this.auth.user.companyId);
        this.welcomeMessage = this.formData.welcomeMessage || null;

        if (await this.featureService.has(FeatureFlag.onboardingVideo)) {
            // TODO: swap out with a direct call for the video when supported
            Employee.query()
                .param('company', this.auth.company.id)
                .with('onboardingVideo.video')
                .find(this.employee.id)
                .then((employee: Employee) => {
                    this.video = employee.onboardingVideo?.video;
                });
        }

        this.loadOnboardingOptions();
    }

    ngOnDestroy(): void {
        this.valid.emit({ valid: this.form.valid, data: this.formData });
    }

    private loadOnboardingOptions(): void {
        OnboardingOption.param('company', this.auth.company.id)
            .all()
            .then(([onboardingOptions]) => {
                const defaultWelcomeMessage = onboardingOptions.length
                    ? onboardingOptions[0].defaultWelcomeMessage
                    : null;
                if (this.welcomeMessage === null && defaultWelcomeMessage !== null) {
                    this.welcomeMessage = defaultWelcomeMessage;
                }
            });
    }
}
