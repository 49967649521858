<form #form="ngForm" autocomplete="off">
    <h1 class="ui header">{{ 'forms.setup-admin.header' | translate }}</h1>

    <div class="app-grid">
        <mat-form-field class="columns-6 small-columns-12">
            <mat-label>{{ 'forms.setup-admin.firstNameLabel' | translate }}</mat-label>
            <input matInput type="text" #firstName="ngModel" name="firstName" [(ngModel)]="formData.firstName" />
            <mat-error *ngIf="firstName.invalid">{{ firstName.errors | formErrors }}</mat-error>
        </mat-form-field>

        <mat-form-field class="columns-6 small-columns-12">
            <mat-label>{{ 'forms.setup-admin.lastNameLabel' | translate }}</mat-label>
            <input matInput type="text" #lastName="ngModel" name="lastName" [(ngModel)]="formData.lastName" />
            <mat-error *ngIf="lastName.invalid">{{ lastName.errors | formErrors }}</mat-error>
        </mat-form-field>

        <mat-form-field class="columns-6 small-columns-12">
            <mat-label>{{ 'forms.setup-admin.dateHiredLabel' | translate }}</mat-label>
            <input
                matInput
                type="text"
                placeholder="{{ 'forms.setup-admin.selectDatePlaceholder' | translate }}"
                required
                #hiredAt="ngModel"
                name="hiredAt"
                [(ngModel)]="formData.hiredAt"
                [matDatepicker]="picker"
            />
            <mat-datepicker-toggle matSuffix [for]="picker">
                <mat-icon matDatepickerToggleIcon svgIcon="calendar"></mat-icon>
            </mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>

        <mat-form-field class="columns-6 small-columns-12">
            <mat-label>{{ 'forms.setup-admin.profilePhotoLabel' | translate }}</mat-label>
            <ui-mat-file-upload
                #avatarInput="ngModel"
                [ngModel]="avatar"
                (fileSelected)="saveAvatar($event)"
                placeholder="{{ 'forms.setup-admin.uploadAvatarPlaceholder' | translate }}"
                [allowedTypes]="imagesOnly"
                name="avatar"
            ></ui-mat-file-upload>
            <ui-button type="outline">{{ 'forms.setup-admin.browse' | translate }}</ui-button>
            <mat-error *ngIf="avatarInput.invalid">{{ avatarInput.errors | formErrors }}</mat-error>
        </mat-form-field>
    </div>
</form>
