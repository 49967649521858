import { Component, OnDestroy, OnInit } from '@angular/core';
import { CompanyMetaService } from '@app/modules/company/services/company-meta.service';
import { AuthService } from '@app/services/auth.service';
import { NotifyService } from '@app/services/notify.service';
import { Company } from '@models/company/company.model';
import { Office } from '@models/company/office.model';
import { Employee } from '@models/employee/employee.model';
import { Card } from '@models/settings/billing/card.model';
import { TranslateService } from '@ngx-translate/core';

const STEPS = ['planReview', 'company', 'administrator', 'payment', 'getStarted'] as const;

type Step = (typeof STEPS)[number];

@Component({
    templateUrl: 'setup.view.html',
    styleUrls: ['setup.style.scss'],
})
export class SetupView implements OnInit, OnDestroy {
    isLoading = true;
    currentStepValid = false;
    stepData: Record<Step, any> = {
        planReview: {},
        company: {},
        administrator: {},
        payment: {},
        getStarted: {},
    };
    errorMessage: string | null;

    get currentStep(): Step {
        return STEPS[this.currentStepIndex];
    }
    currentStepIndex = 0;

    constructor(
        public auth: AuthService,
        private companyMetaService: CompanyMetaService,
        private notify: NotifyService,
        public translateService: TranslateService
    ) {}

    async ngOnInit(): Promise<void> {
        $('body').addClass('login');
        $(window).resize(() => {
            this.renderForm();
        });

        try {
            // Fetch company data
            const company = await Company.with('reps').find(this.auth.user.companyId);
            this.stepData.company = company;
            this.stepData.getStarted = company;

            // Fetch employee data
            const employee = await Employee.param('company', this.auth.user.companyId).find(this.auth.user.id);
            employee.firstName = this.auth.account.legalFirstName;
            employee.lastName = this.auth.account.legalLastName;

            this.stepData.administrator = employee;

            this.renderForm();
        } catch (error) {
            console.error('Error loading data:', error);
        }
    }

    ngOnDestroy(): void {
        $('body').removeClass('login');
    }

    onNextStep(): void {
        if (this.currentStep === 'payment') {
            this.done();
        }

        if (this.stepData.company) {
            this.stepData.payment.address = this.stepData.company.address;
            this.stepData.payment.city = this.stepData.company.city;
            this.stepData.payment.province = this.stepData.company.province;
            this.stepData.payment.postalCode = this.stepData.company.postalCode;
            this.stepData.payment.country = this.stepData.company.country;
        }

        this.currentStepIndex += 1;
    }

    onBackStep(): void {
        this.currentStepIndex -= 1;
    }

    stepValidated(valid: boolean, data: any, step: Step): void {
        this.stepData[step] = data;
        this.currentStepValid = valid;
    }

    done(): void {
        this.isLoading = true;
        this.errorMessage = null;

        this.updateCompany()
            .then((company) => this.createCompanyAddress(company))
            .then((company) => this.updateAdmin(company))
            .then((company) => {
                this.updateTosEmail();
                return company;
            })
            .then((company) => this.createTrial(company))
            .then(() => (this.isLoading = false))
            .catch(({ err }) => {
                this.errorMessage = err?.message;
                this.notify.error('error');
                this.isLoading = false;
            });
    }

    goToDashboard(): void {
        window.location.href = '/dashboard';
    }

    /**
     * Adjust the login form to center window
     * Ensure body has classes related to login
     */
    private renderForm(): void {
        setTimeout(() => {
            $('body').addClass('login');
            $('.ui.checkbox').checkbox();
            $('[name="email"]').focus();
            this.isLoading = false;
        });
    }

    private updateCompany(): Promise<Company> {
        return new Promise<Company>((resolve, reject) => {
            const companyData = this.stepData.company;
            companyData.doingBusinessAsName = companyData.name;

            if (!companyData.prTenantId) {
                delete companyData.prTenantId;
            }
            if (!companyData.prCompanyId) {
                delete companyData.prCompanyId;
            }

            companyData
                .save()
                .then((company) => {
                    resolve(company);
                })
                .catch(() => {
                    reject('Could not update company');
                });
        });
    }

    private createCompanyAddress(company: Company): Promise<Company> {
        return new Promise<Company>((resolve, reject) => {
            const office = new Office({
                name: this.stepData.company.address,
                addressLine1: this.stepData.company.address,
                city: this.stepData.company.city,
                province: this.stepData.company.province,
                country: this.stepData.company.country,
                postalCode: this.stepData.company.postalCode,
            });

            office
                .param('company', this.auth.user.companyId)
                .save()
                .then(() => {
                    resolve(company);
                })
                .catch(() => {
                    reject('Could not create company office');
                });
        });
    }

    private updateAdmin(company: Company): Promise<Company> {
        return new Promise<Company>((resolve, reject) => {
            const employeeData = this.stepData.administrator;
            employeeData.status = 'active';
            employeeData.employmentType = 'full-time';
            employeeData
                .param('company', this.auth.user.companyId)
                .save()
                .then(() => resolve(company))
                .catch(() => reject('Could not update employee'));
        });
    }

    private createTrial(company: Company): Promise<Company> {
        return new Promise<Company>((resolve, reject) => {
            const trialData = this.stepData.payment;
            const card = new Card({
                token: trialData.token,
            });

            card.save()
                .then((_) => {
                    resolve(company);
                })
                .catch((err) => {
                    reject(err);
                    this.currentStepIndex = 3;
                });
        });
    }

    private async updateTosEmail(): Promise<void> {
        try {
            await this.companyMetaService.setTosComplete(this.auth.account.email);
        } catch (error) {
            console.error('Error accepting Terms of Service:', error);
        }
    }
}
