import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { Invoice, InvoiceLineItem } from '@models/settings/billing/invoice.model';
import { Subscription } from '@models/settings/billing/subscription.model';
import { TranslateService } from '@ngx-translate/core';

declare let Stripe: any;

@Injectable({
    providedIn: 'root',
})
export class InvoiceService {
    stripe: any;
    stripe_elements: any;
    card_element: any;

    constructor(private readonly translateService: TranslateService) {}

    setupStripe() {
        this.stripe = Stripe(environment.stripeKey, {
            locale: this.translateService.currentLang.startsWith('fr') ? 'fr' : 'en',
        });
        this.stripe_elements = this.stripe.elements();
        this.card_element = this.stripe_elements.create('card', { hidePostalCode: true });
        return this.card_element;
    }

    createToken(cardElement, formData): Promise<any> {
        return this.stripe.createToken(cardElement, {
            address_line1: formData['address'],
            address_city: formData['city'],
            address_state: formData['province'],
            address_zip: formData['postalCode'],
            address_country: formData['country'],
        });
    }

    // Subscription and invoice handling
    async getSubscription(): Promise<Subscription | null> {
        const [subscriptions] = await Subscription.get();
        return subscriptions.length ? subscriptions[0] : null;
    }

    async getInvoiceLineItems(): Promise<InvoiceLineItem[]> {
        const [invoices] = await Invoice.get();
        return invoices.reduce((lineItems, { lines }) => [...lineItems, ...lines], []);
    }

    // Calculations for invoice subtotals and discounts
    calculateMonthlySubtotal(lineItems: InvoiceLineItem[]): number {
        return lineItems.filter(({ plan }) => plan).reduce((sum, { amount }) => sum + amount, 0);
    }

    calculateDueNowSubtotal(lineItems: InvoiceLineItem[]): number {
        const total = lineItems.filter(({ plan }) => !plan).reduce((sum, { amount }) => sum + amount, 0);
        return this.convertToCurrency(total);
    }

    getPercentDiscount(subscription: Subscription): number {
        return subscription?.discount?.coupon?.percentOff || 0;
    }

    getDollarDiscount(subscription: Subscription): number {
        return subscription?.discount?.coupon?.amountOff || 0;
    }

    applyPercentageDiscount(amount: number, percentDiscount: number): number {
        const discountedAmount = amount * (1 - percentDiscount / 100);
        return this.convertToCurrency(discountedAmount);
    }

    applyDollarDiscount(amount: number, dollarDiscount: number): number {
        const discountedAmount = amount - dollarDiscount;
        return this.convertToCurrency(discountedAmount);
    }

    calculateDiscountedTotal(amount: number, percentDiscount: number, dollarDiscount: number): number {
        const amountAfterPercent = this.applyPercentageDiscount(amount, percentDiscount);
        return this.applyDollarDiscount(amountAfterPercent, dollarDiscount);
    }

    calculateDiscountedMonthlyAfterPercentSubtotal(monthlySubtotal: number, percentDiscount: number): number {
        const discountedSubtotal = monthlySubtotal * (1 - percentDiscount / 100);
        return this.convertToCurrency(discountedSubtotal);
    }

    calculateMonthlySubtotalAfterDollarDiscount(monthlySubtotal: number, dollarDiscount: number): number {
        const discountedSubtotal = monthlySubtotal - dollarDiscount;
        return this.convertToCurrency(discountedSubtotal);
    }

    convertToCurrency(amount: number): number {
        return amount / 100;
    }
}
