<form class="ui form" #form="ngForm" autocomplete="off" [class.loading]="isLoading">
    <table *ngIf="invoiceLineItems?.length" class="ui table">
        <thead>
            <tr>
                <th>{{ 'forms.setup-admin-welcome.yourPlan' | translate }}</th>
                <th></th>
                <th class="right aligned">{{ 'forms.setup-admin-welcome.pricing' | translate }}</th>
            </tr>
        </thead>
        <tbody>
            <ng-container *ngFor="let item of invoiceLineItems; let index = index">
                <tr *ngIf="item['amount'] > 0">
                    <td>{{ item['description'] }}</td>
                    <td></td>
                    <td class="right aligned">{{ item['amount'] / 100 | i18nNum: '$' }}</td>
                </tr>
            </ng-container>
        </tbody>
        <tfoot>
            <tr *ngIf="dueNowSubtotal">
                <td></td>
                <td class="right aligned">{{ 'forms.setup-admin-welcome.dueNow' | translate }}</td>
                <td class="right aligned">{{ dueNowSubtotal | i18nNum: '$' }}*</td>
            </tr>
            <tr *ngIf="subscription?.status === 'trialing'">
                <td></td>
                <td class="right aligned">{{ 'forms.setup-admin-welcome.firstInvoiceDate' | translate }}</td>
                <td class="right aligned">{{ subscription?.trialEnd | appDate }}</td>
            </tr>
            <tr *ngIf="monthlySubtotal">
                <td></td>
                <td class="right aligned">{{ 'forms.setup-admin-welcome.monthlySubtotal' | translate }}</td>
                <td class="right aligned">{{ formatCurrency(monthlySubtotal) | i18nNum: '$' }}*</td>
            </tr>

            <tr *ngIf="percentDiscount">
                <td></td>
                <td class="right aligned">{{ 'forms.setup-admin-welcome.discount' | translate }}</td>
                <td class="right aligned">{{ percentDiscount | i18nNum: '%' }}</td>
            </tr>

            <tr *ngIf="dollarDiscount && !percentDiscount">
                <td></td>
                <td class="right aligned">{{ 'forms.setup-admin-welcome.monthlyDiscount' | translate }}</td>
                <td class="right aligned">{{ formatCurrency(dollarDiscount) | i18nNum: '$' }}</td>
            </tr>

            <tr *ngIf="monthlySubtotal">
                <td></td>
                @if (percentDiscount) {
                    <td class="right aligned">
                        <strong>{{ 'forms.setup-admin-welcome.monthlyTotalAfterDiscount' | translate }}</strong>
                    </td>
                    <td class="right aligned">
                        <strong>{{ discountedMonthlyAfterPercentSubtotal | i18nNum: '$' }}</strong>
                    </td>
                } @else if (dollarDiscount) {
                    <td class="right aligned">
                        <strong>{{ 'forms.setup-admin-welcome.monthlyTotalAfterDiscount' | translate }}</strong>
                    </td>
                    <td class="right aligned">
                        <strong>{{ monthlySubtotalAfterDollarDiscount | i18nNum: '$' }}</strong>
                    </td>
                } @else {
                    <td class="right aligned">
                        <strong>{{ 'forms.setup-admin-welcome.monthlyTotal' | translate }}</strong>
                    </td>
                    <td class="right aligned">
                        <strong>{{ formatCurrency(monthlySubtotal) | i18nNum: '$' }}</strong>
                    </td>
                }
            </tr>

            <tr>
                <td></td>
                <td class="right aligned"></td>
                <td class="right aligned">
                    <small>{{ 'forms.setup-admin-welcome.plusApplicableTaxes' | translate }}</small>
                </td>
            </tr>
        </tfoot>
    </table>
</form>
