import { Languages } from '@account/enums/languages.enum';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { BreakpointObserver } from '@angular/cdk/layout';
import { Location } from '@angular/common';
import { Component, NgZone, OnInit, ViewChild } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import {
    ActivatedRoute,
    NavigationCancel,
    NavigationEnd,
    NavigationStart,
    Router,
    RoutesRecognized,
} from '@angular/router';
import { SidebarComponent } from '@app/components';
import { AnalyticEvents } from '@app/enums';
import { X_LANG_HEADER } from '@app/functions/x-lang';
import { AngularComponentType, SetupGuideConfig, setupGuidesMeta } from '@app/meta/setup-guide.meta';
import { SetupGuide } from '@app/models/company/setup-guide.model';
import { SpecialInformationBarService } from '@app/modules/special-information/special-information-bar.service';
import { AnalyticService, IconsService, TranslationStorageService } from '@app/services';
import { AuthService } from '@app/services/auth.service';
import { FeatureService } from '@app/services/feature.service';
import { TranslateDebuggerStoreService } from '@app/services/language/translate-debugger-store.service';
import { LOCAL_STORAGE_KEYS } from '@app/services/local-storage/local-storage-key';
import { LocalStorageService } from '@app/services/local-storage/local-storage.service';
import { MobileSidenavService } from '@app/services/mobile-sidenav.service';
import { RightNavService } from '@app/services/setup-guide/right-side-nav.service';
import { ViewportService } from '@app/services/viewport.service';
import { breakPoints } from '@app/styles/theme';
import { environment } from '@env/environment';
import { EnvironmentSettings } from '@env/environment-interface';
import { TranslateService } from '@ngx-translate/core';
import * as Sentry from '@sentry/browser';
import { take } from 'rxjs/operators';

declare let FS: { getCurrentSessionURL: () => string; shutdown: () => void };
declare let TrackJS: {
    install: ({ token, application }: { token?: string; application?: string }) => void;
};

const HUMI_COMPANY_ID = 120;
const AUTH0_URLS = ['/auth0login', '/auth0callback', '/logout'];
const OAUTH_URLS = ['/OAuthLogin'];
const UNAUTHENTICATED_PATHS = ['login', 'activateInvitation', 'guest', 'resetPassword'];

@Component({
    selector: 'app-root',
    templateUrl: './app.view.html',
    styleUrls: ['./app.style.scss', './envbar.style.scss'],
    animations: [
        trigger('fadeInOut', [
            state('void', style({ opacity: 0 })),
            transition(':enter', [animate('.2s', style({ opacity: 0.4 }))]),
            transition(':leave', [animate('.2s', style({ opacity: 0 }))]),
        ]),

        trigger('buttonAnimation', [
            state(
                'default',
                style({
                    width: '*',
                    opacity: 1,
                })
            ),
            state(
                'hover',
                style({
                    opacity: 1,
                    width: '140px',
                    'border-radius': '24px',
                })
            ),
            transition('default => hover', animate('.1s ease-out')),
            transition('hover => default', animate('.1s ease-in')),
        ]),
    ],
})
export class AppComponent implements OnInit {
    @ViewChild('sidebar', { static: false }) sidebar!: SidebarComponent;

    hideSidebar = false;
    hideTopbar = false;

    toggleSetupGuide = false;
    currentModule = '';

    state = 'default';

    // In the future we can add conditions to make the sidenav have different setup guides.
    // That change will probably go here
    currentSideNavComponent!: AngularComponentType | null;
    /**
     * specialInformationBarHeight is a string with the current height in pixels of the
     * SpecialInformationBar. We use this to set a (nearly) global css variable which
     * is used in UI calculations.
     */
    specialInformationBarHeight = '0px';

    env: EnvironmentSettings = environment;

    private showMobileMenu = false;

    constructor(
        private analyticService: AnalyticService,
        private router: Router,
        private location: Location,
        private auth: AuthService,
        private zone: NgZone,
        private icons: IconsService,
        private viewport: ViewportService,
        private featureService: FeatureService,
        private translate: TranslateService,
        private translationStorage: TranslationStorageService,
        private translationsDebug: TranslateDebuggerStoreService,
        private specialInformationBarService: SpecialInformationBarService,
        public rightNavService: RightNavService,
        private mobileSidenavService: MobileSidenavService,
        private breakpointObserver: BreakpointObserver,
        private localStorageService: LocalStorageService
    ) {
        this.icons.registerIcons();
        this.enableTranslationFeatures();

        this.specialInformationBarService.specialInformationBarHeight$
            .pipe(takeUntilDestroyed())
            .subscribe((height) => {
                this.specialInformationBarHeight = `${height}px`;
            });

        this.mobileSidenavService.mobileSidenavStatus$
            .pipe(takeUntilDestroyed())
            .subscribe((mobileSidenavStatus) => (this.showMobileMenu = mobileSidenavStatus.isOpen));
    }

    async enableTranslationFeatures(): Promise<void> {
        try {
            this.detectUrlParamLanguage();
        } catch (e) {
            // If for whatever reason we fail to detect the language from the param, we don't want to interrupt this flow to enable translations
        }

        localStorage.setItem(
            X_LANG_HEADER,
            this.translationsDebug.isDebugEnabled() ? 'debug' : this.translationStorage.locale
        );
        this.applyNgxTranslation();
    }

    toggleMobileMenu(): void {
        this.mobileSidenavService.toggle();
    }

    authenticated(): boolean {
        return this.auth.isAuthenticated && this.auth.hydrated;
    }

    isAuth0Route(): boolean {
        return AUTH0_URLS.includes(window.location.pathname);
    }

    isOauthRoute(): boolean {
        return OAUTH_URLS.includes(window.location.pathname);
    }

    ngOnInit(): void {
        this.initializeExternals();
        this.subscribeToRouterEvents();

        // The auth service needs to hydrate before we can call the show the sidenav for setup guides.
        this.auth.onHydrate.subscribe(() => {
            this.showSetupGuide(this.location.path());
            // Stop the FullStory script on production if the user belongs to Humi
            if (environment.production && this.auth.company.id === HUMI_COMPANY_ID) {
                FS.shutdown();
            }
        });

        this.rightNavService.isOpen$.subscribe((isOpen) => {
            this.toggleSetupGuide = isOpen;
        });

        this.zone.runOutsideAngular(() => {
            $(window).resize(() => {
                this.resizeComponents();
            });
            $(window).resize();
            $(window).focus(() => {
                this.sendKeepAlive();
            });
        });
    }

    get isSidebarVisible(): boolean {
        return this.authenticated() && (!this.hideSidebar || this.showMobileMenu);
    }

    get isTopbarVisible(): boolean {
        return this.authenticated() && !this.hideTopbar;
    }

    showMobileOverlay(): boolean {
        return this.showMobileMenu && this.isSidebarVisible;
    }

    /**
     * Function called upon route activation (when a view component is initialized)
     * @param {any} e      [description]
     * @param {any} outlet [description]
     */
    onRouteActivate({ route }: { route: ActivatedRoute }, outlet: HTMLElement): void {
        // Set initial viewport if not already done so by router events subscription
        if (!this.viewport.isInitialized) {
            this.viewport.updateViewport(route.snapshot?.data?.viewport);
        }

        // Scroll to top of page on page change
        outlet.scrollTop = 0;
        setTimeout(() => {
            $('.ui.outlet').stop().animate({ scrollTop: 0 }, 250);
        });
    }

    trackSetupGuideOpened(): void {
        this.analyticService.trackEvent(AnalyticEvents.TimeOffSelfServeOpenGuide);
    }

    /**
     * Check if the keepAlive endpoint is authenticated
     * reauthenticate if not
     */
    private sendKeepAlive(): void {
        if (this.auth.isAuthenticated && !this.isAuth0Route()) {
            this.auth.keepAlive().catch(() => {
                this.auth
                    .reauthenticate()
                    .then(() => {
                        this.sendKeepAlive();
                    })
                    .catch(() => {
                        window.location.reload();
                    });
            });
        }
    }

    /**
     * Subscribe to route change events
     * powers the main loading screen, modals autoclose,
     * error message autoclose, etc
     */
    private subscribeToRouterEvents(): void {
        this.router.events.subscribe((event) => {
            if (event instanceof RoutesRecognized) {
                let route = event.state.root;
                while (route.firstChild) {
                    route = route.firstChild;
                }
                this.viewport.updateViewport(route.data);

                // Need the auth service to be hydrated before calling
                if (this.auth.hydrated) {
                    this.showSetupGuide(event.urlAfterRedirects);
                }

                const { hideSidebar, hideTopbar } = route.data;
                this.hideSidebar = !!hideSidebar;
                this.hideTopbar = !!hideTopbar;
            } else if (event instanceof NavigationStart) {
                this.showSpinner();
            } else if (event instanceof NavigationEnd || event instanceof NavigationCancel) {
                this.hideSpinner();
            }
        });
    }
    /**
     *
     * @param event
     *
     * This function will determine if a setup guide needs to be shown for specific module.
     * This is based on two factors:
     * 1. The endpoint which will return all setup guides for a company per module
     * 2. The setupGuideMeta which will tell the front-end show the specific guide based
     * on a path and feature flag
     */
    private async showSetupGuide(url: string): Promise<void> {
        const setupGuides = this.auth?.company?.setupGuides ?? [];
        if (setupGuides.length === 0 || this.isCurrentModule(url)) {
            return;
        }

        const { setupGuideMeta, guide } = this.findSetupGuideMeta(setupGuides, url);
        if (!setupGuideMeta || !guide || !(await this.shouldDisplayGuide(setupGuideMeta, guide))) {
            this.resetNavigation();
            return;
        }

        this.updateNavigation(setupGuideMeta);
    }

    private isCurrentModule(url: string): boolean {
        return Boolean(this.currentModule && url.includes(this.currentModule));
    }

    private findSetupGuideMeta(
        setupGuides: SetupGuide[],
        url: string
    ): { setupGuideMeta: SetupGuideConfig | undefined; guide: any | undefined } {
        for (const guide of setupGuides) {
            const meta = setupGuidesMeta[guide.module.name];
            if (meta && new RegExp(meta.path).test(url)) {
                return { setupGuideMeta: meta, guide };
            }
        }

        return { setupGuideMeta: undefined, guide: undefined };
    }

    private async shouldDisplayGuide(meta: SetupGuideConfig, guide: SetupGuide): Promise<boolean> {
        const isMobile = this.breakpointObserver.isMatched(`(max-width: ${breakPoints.md}px)`);
        const hasFeatureFlag = meta.showIfFeatureFlag ? await this.featureService.has(meta.showIfFeatureFlag) : true;
        const isAdminCheckPassed = !meta.isAdmin || this.auth.isAdmin();
        const isGuideCompleted = !!guide.finalActionCompletedAt;

        return !isMobile && hasFeatureFlag && isAdminCheckPassed && !isGuideCompleted;
    }

    private resetNavigation(): void {
        this.currentModule = '';
        this.currentSideNavComponent = null;
        this.rightNavService.hide();
        this.rightNavService.close();
    }

    private async updateNavigation(meta: SetupGuideConfig): Promise<void> {
        this.currentModule = meta.path;
        this.currentSideNavComponent = meta.component;
        this.rightNavService.show();

        const sidebarHidden =
            (await this.localStorageService.retrieve<boolean>(LOCAL_STORAGE_KEYS.rightNavBarClosed)) === true;
        meta.isOpen && !sidebarHidden ? this.rightNavService.open() : this.rightNavService.close();
    }

    private showSpinner(): void {
        // Auth routes are already spinners
        if (this.isAuth0Route() || this.isOauthRoute()) {
            return;
        }
        $('.app-loader').show();
        $('.app-loader').addClass('active');
        $('error-message').fadeOut();
        $('.ui.modal').modal('hide');
        $('.dropdown').dropdown('hide');
        if ($('app-sidebar').hasClass('active')) {
            $('app-sidebar').css({
                transition: 'transform .25s',
                transform: 'translate(-100%, 0)',
            });
            $('app-sidebar').removeClass('active');
        }
    }

    private hideSpinner(): void {
        $('.app-loader').hide();
        $('.app-loader').removeClass('active');
        $('.dropdown').dropdown('hide');
        if ($('app-sidebar').hasClass('active')) {
            $('app-sidebar').css({
                transition: 'transform .25s',
                transform: 'translate(-100%, 0)',
            });
            $('app-sidebar').removeClass('active');
        }
        this.resizeComponents();
    }

    /**
     * Initialize the external libraries
     * - Fullstory
     * - trackJS
     */
    private initializeExternals(): void {
        this.initializeTrackJS();
        if (environment.production) {
            this.initFullstory();
        }
    }

    private initializeTrackJS(): void {
        try {
            TrackJS.install({
                token: environment.trackjs,
                application: environment.name,
            });
        } catch {
            // Whoops
        }
    }

    /**
     * Setup fullstory to screen record users for
     * analytics and support
     */
    private initFullstory(): void {
        (window as Window & typeof globalThis & { _fs_ready: () => void })['_fs_ready'] = (): void => {
            const sessionUrl = FS.getCurrentSessionURL();
            window.fsSessionUrl = sessionUrl;
            Sentry.setTag('fullstory', sessionUrl);
        };
    }

    /**
     * Resize the main display to fit the window like
     * an Application should
     */
    private resizeComponents(): void {
        setTimeout(() => {
            const windowHeight = $(window).height();
            $('app-root').css('width', '100%');
            $('app-root').height(windowHeight);
        });
    }

    private applyNgxTranslation(): void {
        this.translate
            .use(this.translationStorage.locale)
            .pipe(take(1))
            .subscribe({
                next: () => {
                    const rootElement = document.querySelector('html');
                    if (rootElement) {
                        rootElement.lang = this.translationStorage.locale.slice(0, 2);
                    }
                },
                error: (e) => {
                    const message = 'Translation service could not load: ' + e;
                    Sentry.captureException(message);
                },
            });
    }

    /**
     * For unauthenticated pages, detects a "lang" attribute in the url to set the language
     */
    private detectUrlParamLanguage(): void {
        if (UNAUTHENTICATED_PATHS.includes(window.location.pathname.slice(1))) {
            const urlLang = new URLSearchParams(window.location.search).get('lang');
            if (urlLang) {
                this.translationStorage.locale = urlLang == Languages.FRENCH ? Languages.FRENCH : Languages.ENGLISH;
            }
        }
    }
}
