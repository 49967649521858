<form class="ui form" #form="ngForm" autocomplete="off">
    <h1 class="ui header centered">
        {{ 'forms.setup-admin-welcome.header' | translate }}
        <div class="subheader">{{ 'forms.setup-admin-welcome.subheader' | translate }}</div>
    </h1>

    <app-form-setup-payment-info></app-form-setup-payment-info>

    <ui-card variant="dark">
        <div class="scrollable-content" #scrollableContent>
            <div class="fade-overlay" [@fadeEffect]="fadeState"></div>
            <div [innerHTML]="'forms.setup-admin-welcome.agreement' | translate: URLS"></div>
        </div>
    </ui-card>
    <div class="flex justify-center">
        <mat-checkbox [(ngModel)]="formData.terms" #terms="ngModel" name="terms" required type="checkbox">
            <span [innerHTML]="'forms.setup-admin-welcome.acknowledgeTermsPrivacy' | translate: URLS"></span>
        </mat-checkbox>
    </div>
</form>
