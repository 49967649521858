import { registerLocaleData } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import localeFr from '@angular/common/locales/fr-CA';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { environment } from '@env/environment';
import { IModuleTranslationOptions, ModuleTranslateLoader } from '@larscom/ngx-translate-module-loader';
import {
    TranslateModule as NgxTranslateModule,
    TranslateDirective,
    TranslateLoader,
    TranslateParser,
    TranslatePipe,
    TranslateService,
} from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import * as Sentry from '@sentry/angular';
import { Observable, of } from 'rxjs';
import version from '../assets/i18n/merged/version.json';
import { LocaleModule } from './locale.module';
import { AppTranslatePipe } from './pipes';
import { TranslateParserDebuggerService } from './services/language/translate-parser-debugger.service';

registerLocaleData(localeFr);

function appInitializerFactory(translate: TranslateService): () => Promise<any> {
    return (): Promise<any> => {
        const locale = localStorage.getItem('locale') ?? environment.locale;
        const rootElement = document.querySelector('html');
        if (rootElement && locale) {
            rootElement.lang = locale.slice(0, 2);
        }
        return translate.use(locale).toPromise();
    };
}

function createMergedTranslateLoader(http: HttpClient): TranslateHttpLoader | ModuleTranslateLoader {
    const fileVersion = version.number;
    if (!fileVersion) {
        Sentry.captureMessage('No version provided for merged translations.', 'info');
        return moduleHttpLoaderFactory(http);
    }
    return new TranslateHttpLoader(http, '/assets/i18n/merged/', `${fileVersion}.json`);
}

function moduleHttpLoaderFactory(http: HttpClient): ModuleTranslateLoader {
    const baseTranslateUrl = '/assets/i18n';
    // When adding a new module to this list, please make sure to add it to the project's project.json,
    // so when it comes to running translate-linter against the project, the new module is also represented.
    const options: IModuleTranslationOptions = {
        modules: [
            { baseTranslateUrl },
            // final url: ./assets/i18n/<moduleName>/en.json
            { baseTranslateUrl, moduleName: 'account' },
            { baseTranslateUrl, moduleName: 'add-ons' },
            { baseTranslateUrl, moduleName: 'applicant-tracker' },
            { baseTranslateUrl, moduleName: 'benefits' },
            { baseTranslateUrl, moduleName: 'components' },
            { baseTranslateUrl, moduleName: 'dashboard' },
            { baseTranslateUrl, moduleName: 'self-serve' },
            { baseTranslateUrl, moduleName: 'offer-letter' },
            { baseTranslateUrl, moduleName: 'time-tracking' },
            { baseTranslateUrl, moduleName: 'time-off-v3' },
            { baseTranslateUrl, moduleName: 'forms' },
            { baseTranslateUrl, moduleName: 'table' },
            { baseTranslateUrl, moduleName: 'components' },
            { baseTranslateUrl, moduleName: 'archived-notifications' },
            { baseTranslateUrl, moduleName: 'training' },
            { baseTranslateUrl, moduleName: 'employees' },
            { baseTranslateUrl, moduleName: 'change-requests' },
            { baseTranslateUrl, moduleName: 'document' },
            { baseTranslateUrl, moduleName: 'company' },
            { baseTranslateUrl, moduleName: 'videos' },
            { baseTranslateUrl, moduleName: 'settings' },
            { baseTranslateUrl, moduleName: 'surveys' },
            { baseTranslateUrl, moduleName: 'performance' },
            { baseTranslateUrl, moduleName: 'import' },
            { baseTranslateUrl, moduleName: 'payroll' },
            { baseTranslateUrl, moduleName: 'reporting' },
        ],
        lowercaseNamespace: true,
        version: Date.now(),
    };

    return new ModuleTranslateLoader(http, options);
}

export const TEST_TRANSLATION_STORAGE_KEY = 'test-translations';

class TranslateFakeLoader extends TranslateLoader {
    getTranslation = (locale: string): Observable<any> => {
        return of(JSON.parse(localStorage.getItem(`${TEST_TRANSLATION_STORAGE_KEY}.${locale}`)));
    };
}

@NgModule({
    imports: [
        NgxTranslateModule.forRoot({
            defaultLanguage: 'en_CA',
            loader: {
                provide: TranslateLoader,
                useFactory: environment.name === 'local' ? moduleHttpLoaderFactory : createMergedTranslateLoader,
                deps: [HttpClient],
            },
            parser: { provide: TranslateParser, useClass: TranslateParserDebuggerService },
        }),
        LocaleModule,
    ],
    providers: [
        {
            provide: APP_INITIALIZER,
            useFactory: appInitializerFactory,
            deps: [TranslateService],
            multi: true,
        },
    ],
    exports: [TranslatePipe],
})
export class RootTranslateModule {}

@NgModule({
    imports: [
        NgxTranslateModule.forRoot({
            defaultLanguage: 'en_CA',
            loader: {
                provide: TranslateLoader,
                useClass: TranslateFakeLoader,
            },
        }),
        LocaleModule,
    ],
    providers: [AppTranslatePipe],
    exports: [TranslatePipe, TranslateDirective],
})
export class TestTranslateModule {}
