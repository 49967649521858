<form #form="ngForm" autocomplete="off">
    <h1 class="ui header">
        {{ 'forms.setup-company.welcomeToHumi' | translate }}
        <span class="capitalize">{{ auth?.user?.account?.fullName }}</span>
        <div class="sub header">
            {{ 'forms.setup-company.subheader' | translate }}
        </div>
    </h1>

    <div class="app-grid">
        <mat-form-field class="columns-6 small-columns-12">
            <mat-label>{{ 'forms.setup-company.companyNameLabel' | translate }}</mat-label>
            <input
                matInput
                type="text"
                placeholder="{{ 'forms.setup-company.companyNamePlaceholder' | translate }}"
                required
                #name="ngModel"
                name="name"
                [(ngModel)]="formData.name"
            />
            <mat-error *ngIf="name.invalid">{{ name.errors | formErrors }}</mat-error>
        </mat-form-field>

        <mat-form-field class="columns-6 small-columns-12">
            <mat-label>{{ 'forms.setup-company.dateFoundedLabel' | translate }}</mat-label>
            <input
                matInput
                placeholder="{{ 'forms.setup-company.selectDatePlaceholder' | translate }}"
                required
                #incorporationDate="ngModel"
                name="incorporationDate"
                [(ngModel)]="formData.incorporationDate"
                [matDatepicker]="picker"
            />
            <mat-datepicker-toggle matSuffix [for]="picker">
                <mat-icon matDatepickerToggleIcon svgIcon="calendar"></mat-icon>
            </mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>

            <mat-error *ngIf="incorporationDate.invalid">{{ incorporationDate.errors | formErrors }}</mat-error>
        </mat-form-field>

        <mat-form-field class="columns-6 small-columns-12">
            <mat-label>{{ 'forms.setup-company.companyPhoneLabel' | translate }}</mat-label>
            <input
                matInput
                type="text"
                placeholder="{{ 'forms.setup-company.phoneFormatPlaceholder' | translate }}"
                required
                #phonePrimary="ngModel"
                name="phonePrimary"
                [(ngModel)]="formData.phonePrimary"
            />
            <mat-error *ngIf="phonePrimary.invalid">{{ phonePrimary.errors | formErrors }}</mat-error>
        </mat-form-field>

        <mat-form-field class="columns-6 small-columns-12">
            <mat-label>{{ 'forms.setup-company.streetAddressLabel' | translate }}</mat-label>
            <input
                matInput
                type="text"
                placeholder="{{ 'forms.setup-company.searchAddressPlaceholder' | translate }}"
                required
                #address="ngModel"
                name="address"
                [(ngModel)]="formData.address"
            />
            <mat-error *ngIf="address.invalid">{{ address.errors | formErrors }}</mat-error>
        </mat-form-field>

        <mat-form-field class="columns-6 small-columns-12">
            <mat-label>{{ 'forms.setup-company.countryLabel' | translate }}</mat-label>

            <ui-country-autocomplete
                placeholder="{{ 'forms.setup-company.pleasePickPlaceholder' | translate }}"
                name="country"
                #country="ngModel"
                required
                [(ngModel)]="formData.country"
            ></ui-country-autocomplete>

            <mat-error *ngIf="country.invalid">{{ country.errors | formErrors }}</mat-error>
        </mat-form-field>

        <mat-form-field class="columns-6 small-columns-12">
            <mat-label>{{ 'forms.setup-company.cityLabel' | translate }}</mat-label>
            <input matInput type="text" required #city="ngModel" name="city" [(ngModel)]="formData.city" />
            <mat-error *ngIf="city.invalid">{{ city.errors | formErrors }}</mat-error>
        </mat-form-field>

        <mat-form-field class="columns-6 small-columns-12">
            <mat-label>{{ 'forms.setup-company.stateOrProvinceLabel' | translate }}</mat-label>

            <ui-state-autocomplete
                required
                placeholder="{{ 'forms.setup-company.selectStatePlaceholder' | translate }}"
                name="province"
                [country]="formData.country"
                [(ngModel)]="formData.province"
                #state="ngModel"
            ></ui-state-autocomplete>
            <mat-error *ngIf="state.invalid">{{ state.errors | formErrors }}</mat-error>
        </mat-form-field>

        <mat-form-field class="columns-6 small-columns-12">
            <mat-label>{{ 'forms.setup-company.postalCodeLabel' | translate }}</mat-label>
            <input
                matInput
                type="text"
                required
                #postalCode="ngModel"
                name="postal_code"
                [(ngModel)]="formData.postalCode"
            />
            <mat-error *ngIf="postalCode.invalid">{{ postalCode.errors | formErrors }}</mat-error>
        </mat-form-field>

        <mat-form-field class="columns-6 small-columns-12">
            <mat-label>{{ 'forms.setup-company.companyLogoLabel' | translate }}</mat-label>
            <ui-mat-file-upload
                #logoInput="ngModel"
                name="logoId"
                [ngModel]="logo"
                (fileSelected)="saveLogo($event)"
                placeholder="{{ 'forms.setup-company.selectLogoPlaceholder' | translate }}"
                [allowedTypes]="['.png', '.gif', '.jpg', '.jpeg', '.svg']"
            ></ui-mat-file-upload>
            <ui-button type="outline">{{ 'forms.setup-company.browse' | translate }}</ui-button>
            <mat-error *ngIf="logoInput.invalid">{{ logoInput.errors | formErrors }}</mat-error>
        </mat-form-field>
    </div>
</form>
